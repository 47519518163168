


























import { fulfillmentStatuses } from "@/constants/orders";
import userManager from "@/services/userManager";
import Vue from "vue";

export default Vue.extend({
  props: ["item"],
  data() {
    return {
      self: this,
      tableOptions: null,
    };
  },
  methods: {
    makeTableOptions() {
      const self = this;
      return {
        attrs: {
          "item-key": "id",
          "hide-default-footer": true,
          "items-per-page": -1,
          "footer-props": {
            "items-per-page-options": [-1],
          },
          "fixed-header": false,
        },
        content: {
          name: this.item.orderId,
          urlEnabled: false,
          search: {
            ext: {
              hidden: true,
            },
          },
          displayFields: {
            imageUrl: {
              text: "Image",
            },
            fulfillmentStatus: {
              text: "Fulfillment Status",
              options: {
                label(value) {
                  return fulfillmentStatuses[value];
                },
              },
            },
            fulfillmentQualityCheckFailed: {
              text: "Quality Check",
              options: {
                preTransform(value) {
                  return !value;
                },
                boolean: true,
              },
            },
            quantity: {
              text: "Quantity",
            },
            name: {
              text: "Name",
            },
            code: {
              text: "Code",
            },
            price: userManager.checkRole(["admin"]) && {
              text: "Price",
            },
            deliveryType: {
              text: "Delivery Type",
            },
            categoryCode: {
              text: "Category Code",
            },
            id: {
              text: "ID",
            },
          },
        },
        ext: {
          dataProvider: {
            async findAll() {
              return [self.item.productItems, self.item.productItems.length];
            },
          },
        },
      };
    },
  },
  async created() {
    this.tableOptions = this.makeTableOptions();
  },
});
