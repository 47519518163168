export const sourceTypes = {
    manual: "Manual",
    orderDesk: "Order Desk",
    other: "Other",
    MA: "MA",
}

export const statuses = {
    open: "Open",
    processing: "Processing",
    shipping: "Shipping",
    completed: "Completed",
    cancelled: "Cancelled",
}

export const shipVendorTypes = {
    other: "Other",
}

export const paymentStatuses = {
    approved: "Approved",
    authorized: "Authorized",
    captured: "Captured",
    fullyRefunded: "Fully Refunded",
    partiallyRefunded: "Partially Refunded",
    pending: "Pending",
    rejected: "Rejected",
    voided: "Voided",
    other: "Other",
}

export const shippingStatuses = {
    open: "Open",
    onDelivery: "On Delivery",
    delivered: "Delivered",
    cancelled: "Cancelled",
    onReturn: "On Return",
    returned: "Returned",
    losted: "Losted",
    destroyed: "Destroyed",
    damaged: "Damaged",
}

export const fulfillmentStatuses = {
    begin: "Begin",
    barcodePrinted: "Barcode Printed",
    designPrinted: "Design Printed",
    mainPrinted: "Printed",
    partiallyPackaged: "Partially Packaged",
    packaged: "Packaged",
}
